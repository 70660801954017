// Here you can add other styles
.user-edit-button {
  background: rgb(51, 153, 255) !important;
  box-shadow: unset !important;
  cursor: pointer;

  &:hover {
    background: rgb(33, 124, 215) !important;
  }
}

.user-input-width {
  min-width: 100px !important;
}

// Loading
.loading {
  z-index: 999999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(180 180 180 / 30%);
  backdrop-filter: blur(3px);

  &__inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.food-item {
  margin-bottom: 24px;
  background: #f8f8f8;
  border: 1px solid #3c4b64;
  border-radius: 4px;

  h5 {
    margin: 8px;
  }
}

.btns-menu {
  display: flex;
  justify-content: flex-end;
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin-right: 16px;
  }
}

// Create menu
.menu-info {
  display: flex;
  justify-content: space-between;
}

.date-nav {
  display: flex;
  align-items: center;

  .react-datepicker-wrapper {
    width: auto !important;
    input {
      height: 40px;
      width: 248px;
      padding: 8px;
    }
  }
  button {
    padding: 8px 64px;
  }

  .save {
    margin-left: 8px;
    background: #63a652 !important;
  }
  .lock {
    background: #4f6287 !important;
    margin-left: auto;
  }
}

.food-container {
  margin-top: 16px;
  background: #f4f6f8;
  border: 1px solid #adbcc2;
  border-radius: 4px;
  min-height: 80px;
  padding: 24px;
}

.food-container.exstras {
  display: flex;
  flex-direction: column;

  & > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  div:nth-child(1) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 8px;
    p {
      margin: 0;
      margin-right: 4px;
    }
  }

  .extra-btn {
    background: #63a652;
    border-radius: 20px;
    padding: 8px 23px;
    border: none;
    color: #fff;

    display: flex;
    align-items: center;
    flex-shrink: 0;
  }

  .remove {
    margin-left: auto;
    border-radius: 20px;
    flex-shrink: 0;
    margin-bottom: auto;
  }
}

.food-main {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 14px;
}
// Food
.food-item {
  width: calc(50% - 7px);
  border-radius: 4px;
  h5,
  p {
    margin: 0;
  }

  &__header {
    padding: 8px 20px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #ccc;
    button {
      border: none;
      background: transparent;

      svg {
        transform: scale(1.3);
      }
    }
  }

  .extras {
    padding: 8px 20px;

    .extra {
      input {
        position: relative;
        top: 2px;
        margin-right: 4px;
      }
    }
  }
}

.singles {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 14px;
}

.food-item-single {
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  border-radius: 4px;
  width: calc(50% - 7px);
  cursor: pointer;
  h5 {
    margin: 0;
  }
}

//cards
.card-body .card-search {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
}
.card-body .card-search input {
  border: 1px solid #adbcc2;
  border-radius: 4px;
  font-size: 14px;
  padding: 4px 8px;
  min-width: 220px;
}

///report
///
#report-container,
#report-item {
  max-width: 1440px;
  margin: 50px auto;

  .daily-report-container {
    background: #fff;
    border-radius: 8px;
    overflow: hidden;

    &:not(:last-child) {
      margin-bottom: 48px;
    }

    .report-shift {
      background: #4f5d73;
      border: 1px solid #d2d2d6;
      border-radius: 8px;
      padding: 24px 32px;

      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 18px;

      color: #ffffff;
    }

    .report-data {
      padding: 32px 24px;

      .meal-box {
        border: 2px solid #d7d7d7;
        border-radius: 8px;

        &:not(:last-child) {
          margin-bottom: 24px;
        }

        .company-name-header {
          background: #e9e9ec;
          border-radius: 8px;
          padding: 16px;

          font-size: 16px;
          span {
            font-weight: 700;
          }
        }

        .meal-box-data {
          padding: 24px 32px;

          h3 {
            font-size: 16px;
            margin-bottom: 24px;
          }

          ul {
            list-style: none;
            display: flex;
            flex-direction: column;
            gap: 16px;
            padding: 0;
            li {
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;
              gap: 14px;

              .meal-details {
                display: flex;
                justify-content: space-between;
                background: #f3f3f4;
                border-radius: 4px;
                align-items: center;
                padding: 8px;
                flex: 1;
                @media (max-width: 1024px) {
                  flex: auto;
                  width: 100%;
                }
                p {
                  margin: 0;
                }
              }

              button {
                border: none;
                padding: 12px;
                display: flex;
                align-items: center;
                gap: 4px;
                color: #fff;
                font-size: 14px;
                flex-shrink: 0;

                img {
                  max-width: 24px;
                }
              }

              .show-workers-button {
                background: #3399ff;
                border-radius: 4px;
                @media (max-width: 1024px) {
                  margin-left: auto;
                }
              }

              .print-workers-button {
                background: #63a652;
                border-radius: 4px;
              }
            }
          }

          hr {
            margin-top: 16px;
          }
          .meal-suma {
            margin-top: 16px;
            text-align: right;

            font-size: 16px;
            span {
              font-weight: 700;
            }
          }
        }
      }
    }

    .report-suma {
      background: #ffffff;
      border-width: 2px 0px;
      border-style: solid;
      border-color: #4f5d73;
      border-radius: 8px;
      padding: 32px 40px;
      text-align: right;

      font-size: 16px;
      span {
        font-weight: 700;
      }
    }
  }

  .suma {
    background: #ffffff;

    border-radius: 8px;
    padding: 32px 40px;
    text-align: right;

    font-size: 16px;
    span {
      font-weight: 700;
    }

    margin-bottom: 48px;
  }

  .transfer {
    max-width: 960px;
    margin: 0 auto 46px auto;
    background: #ffffff;
    border-radius: 8px;

    .transfer-header {
      background: #f94d17;
      border: 1px solid #d2d2d6;
      border-radius: 8px;

      color: #fff;
      font-size: 24px;
      padding: 16px;
    }

    .transfer-header-add {
      background: #63a652;
      border: 1px solid #d2d2d6;
      border-radius: 8px;

      color: #fff;
      font-size: 24px;
      padding: 16px;
    }

    .transfer-header-milk {
      background: rgb(51, 153, 255);
      border: 1px solid #d2d2d6;
      border-radius: 8px;

      color: #fff;
      font-size: 24px;
      padding: 16px;
    }

    .food {
      display: flex;
      padding: 32px;
      gap: 32px;
      justify-content: space-between;
      border-bottom: 1px solid #000;
    }

    .food-add {
      display: flex;
      padding: 32px;
      gap: 32px;
      justify-content: space-between;
    }

    .divider-add {
      background: #d7d7d7;
      height: 2px;
      display: block;
      margin: 0 32px;
      opacity: 0.5;
    }

    .picker {
      padding: 32px;
      border-bottom: 1px solid #000;

      .picker-box {
        display: flex;
        gap: 32px;
        justify-content: space-between;
      }

      .divider {
        background: #d7d7d7;
        width: 100%;
        height: 2px;
        display: block;
        margin: 24px 0;
        opacity: 0.5;
      }
    }

    .transfer-form {
      display: flex;
      align-items: center;
      gap: 8px;
      flex: 1;
      &:nth-of-type(1) {
        margin-right: auto;
      }
      &:nth-of-type(2) {
        margin-left: auto;
        label {
          margin-left: auto;
        }
      }

      label {
        min-width: 70px;
      }

      select {
        background: #f3f3f4;
        border-radius: 4px;
        border: none;
        padding: 16px;
        font-family: inherit;
        font-size: 16px;
        max-width: 300px;
        width: 100%;

        &:focus,
        &:active {
          outline: none;
        }
      }

      input {
        background: #f3f3f4;
        border-radius: 4px;
        border: none;
        padding: 16px;
        font-family: inherit;
        font-size: 16px;
        max-width: 300px;
        width: 100%;

        &:focus,
        &:active {
          outline: none;
        }
      }
    }

    .confirm-button-box {
      padding: 48px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      button {
        background: #f94d17;
        border-radius: 4px;
        border: none;
        padding: 16px 48px;
        display: flex;
        align-items: center;
        gap: 4px;
        color: #fff;
        font-size: 20px;

        img {
          max-width: 24px;
          margin-left: 6px;
        }
      }

      .button-add {
        background: #63a652;
        border-radius: 4px;
        border: none;
        padding: 16px 48px;
        display: flex;
        align-items: center;
        gap: 4px;
        color: #fff;
        font-size: 20px;

        img {
          max-width: 24px;
          margin-left: 6px;
        }
      }

      .button-milk {
        background: rgb(51, 153, 255);
        border-radius: 4px;
        border: none;
        padding: 16px 48px;
        display: flex;
        align-items: center;
        gap: 4px;
        color: #fff;
        font-size: 20px;

        img {
          max-width: 24px;
          margin-left: 6px;
        }
      }
    }
  }
}

//
.month-form {
  display: flex;
  gap: 8px;

  .react-datepicker-wrapper {
    width: unset;

    input {
      padding: 8px;
    }
  }
}

.print {
  background: #63a652;
  border-radius: 4px;

  text-decoration: none;
  padding: 8px 16px;
  color: #fff;
  @media (max-width: 1024px) {
    margin-left: auto;
  }

  img {
    width: 20px;
    margin-left: 4px;
  }

  &:hover {
    color: #fff;
  }
}

.createUserGroup {
  display: none;
}

.hide-close-btn {
  button {
    display: none !important;
  }
}

// Company canger
.company-changer {
  border-radius: 5px;
  min-height: 600px;
  padding: 16px;

  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__select {
    display: flex;
    align-items: center;

    gap: 5px;

    h5 {
      margin: 0;
    }
  }
}
